import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import UniqueIdEnqueteApp from '../enquete/components/UniqueIdEnqueteApp';

const haeSurveyData = {
  linkUrl: '',
  linkText: 'さっそく答える（約1〜2分）'
};

const haeEnqueteApp = new Vue({
  render: h => h(UniqueIdEnqueteApp, { props: haeSurveyData })
});

document.addEventListener('DOMContentLoaded', () => {
  const uniqueIdEnqueteApp = document.getElementById('uniqueid-enquete-app');
  if (uniqueIdEnqueteApp?.getAttribute('data-hae-survey') === 'true') {
    const uniqueId = uniqueIdEnqueteApp.getAttribute('data-unique-id');
    const haeSurveyUrl = uniqueIdEnqueteApp.getAttribute('data-hae-survey-url');
    haeSurveyData.linkUrl = `${haeSurveyUrl}?p_id=${uniqueId}`;

    haeEnqueteApp.$mount('#uniqueid-enquete-app');
  }
});
