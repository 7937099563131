<template>
  <unique-id-survey-popup v-if="isDisplay" @close="close" :linkUrl="linkUrl">
    <template #body>
      <hae-survey-body />
    </template>
    <template #linkText>{{ linkText }}</template>
  </unique-id-survey-popup>
</template>

<script>
import dayjs from 'dayjs';
import UniqueIdSurveyPopup from './common/UniqueIdSurveyPopup.vue';
import HaeSurveyBody from './hae/HaeSurveyBody.vue';

export default {
  components: { UniqueIdSurveyPopup, HaeSurveyBody },
  props: {
    linkUrl: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isDisplay: false
    };
  },
  created() {
    this.id = 'unique-id-enquete';
    this.isDisplay = this.display();
  },
  methods: {
    display() {
      return true;
    },
    close() {
      const expireDate = dayjs()
        .add(90, 'days')
        .toDate()
        .toGMTString();
      document.cookie = `disableUniqueIdEnquete=true; expires=${expireDate};`;
      return (this.isDisplay = false);
    }
  }
};
</script>
