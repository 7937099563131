<template>
  <div>
    <p class="enquete-float__title">
      遺伝性血管性浮腫に関する<br />
      アンケートにご協力お願いします
    </p>
    <div class="enquete-float__description">
      <p class="campaigns-dialog-txt">
        特設サイト（遺伝性血管性浮腫（HAE））をご覧いただいた方を対象に、サービス改善のアンケートを実施しています。ぜひご協力をお願いいたします。
      </p>
      <p class="campaigns-dialog-txt">回答いただいた方より</p>
      <ul class="campaigns-dialog-txt">
        <li class="campaigns-dialog-list">
          <b>Amazonギフトカード 500円分</b>を抽選で10名様にプレゼント
        </li>
        <li class="campaigns-dialog-list">
          さらに、<b>インタビューご協力でAmazonギフトカード 2,000円分</b>
          をプレゼント
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HaeSurveyBody'
};
</script>
