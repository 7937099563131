<template>
  <div class="enquete-float">
    <div class="enquete-float__close">
      <button class="enquete-float__close-link" @click="hidePopup">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="enquete-float__inner">
      <p class="enquete-float__description">
        <slot name="body"></slot>
      </p>
      <div class="campaigns-btn">
        <a
          class="button button--primary button--md button--full-sp enquete-float__button text-center"
          target="_blank"
          rel="noopener"
          :href="linkUrl"
          @click="hidePopup"
        >
          <slot name="linkText"></slot>
          <i class="material-icons">chevron_right</i>
        </a>
      </div>
      <p class="text-center">
        <a @click="hidePopup" class="campaigns-dialog-close-link">
          閉じる（表示しない）
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UniqueIdSurveyPopup',
  props: {
    linkUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    hidePopup() {
      this.$emit('close');
    }
  }
};
</script>
